<template>
  <section class="page-wrap">
    <!-- 顶部操作 start -->
    <section class="form jn-form-style">
      <div class="form-item btn-wrap">
        <el-button
          type="primary"
          style="margin-left: 5px"
          icon="el-icon-refresh"
          class="refresh"
          @click="onHandleRefresh"
        ></el-button>
        <el-button @click="onHandleZone('addData')" type="primary">
          新 增
        </el-button>
        <el-button @click="onHandleUpload('addData')" type="primary">
          导入
        </el-button>
      </div>
      <div class="d-flex headSearch">
        <div class="form-item">
          <el-input v-model="formData.name" clearable placeholder="产地">
          </el-input>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.country_code"
            clearable
            placeholder="国家"
            filterable
          >
            <el-option
              :label="countItem.name"
              :value="countItem.code"
              v-for="(countItem, countIndex) in countriesList"
              :key="countIndex"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.province_code"
            clearable
            placeholder="省"
            filterable
          >
            <el-option
              :label="pItem.name"
              :value="pItem.areaCode"
              v-for="(pItem, pIndex) in provinceList"
              :key="pIndex"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.city_code"
            clearable
            placeholder="市"
            filterable
          >
            <el-option
              :label="cityItem.name"
              :value="cityItem.areaCode"
              v-for="(cityItem, cityIndex) in cityList"
              :key="cityIndex"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.district_code"
            clearable
            placeholder="区/县"
            filterable
          >
            <el-option
              :label="areaItem.name"
              :value="areaItem.areaCode"
              v-for="(areaItem, areaIndex) in districtList"
              :key="areaIndex"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item">
          <el-select
            v-model="formData.first_cid"
            clearable
            placeholder="一级品类"
          >
            <el-option
              :label="firstItem.name"
              :value="firstItem.id"
              v-for="(firstItem, firstIndex) in firstCidList"
              :key="firstIndex"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select v-model="formData.status" clearable placeholder="状态">
            <el-option
              :label="sItem.label"
              :value="sItem.value"
              v-for="(sItem, sIndex) in statusList"
              :key="sIndex"
            ></el-option>
          </el-select>
        </div>
        <div class="form-item btn-wrap">
          <el-button
            @click="onHandleSearch"
            type="primary"
            icon="el-icon-search"
            >查询</el-button
          >
        </div>
        <div class="form-item btn-wrap">
          <el-button
            type="warning"
            icon="el-icon-upload2"
            @click="onHandleDownLoad"
            >导出</el-button
          >
        </div>
      </div>
    </section>
    <!-- 顶部操作 end -->

    <!-- 列表 start -->
    <section class="content-wrap">
      <table-list
        v-loading="loadingData"
        :tableData="tableData"
        :id="formData.id"
        @submit-form="submitForm"
        @onHandleZone="onHandleZone"
      ></table-list>
    </section>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 分页 end -->

    <!-- 新增弹窗 start -->
    <association-config-dialog
      ref="associationConfig"
      :id="formData.id"
      @submit-form="submitForm"
      :firstCidList="firstCidList"
      :countriesList="countriesList"
      :areasList="areasList"
    ></association-config-dialog>
    <!-- 新增弹窗 end -->

    <!-- 上传表格弹窗 start -->
    <upload-file
      ref="uploadFileRef"
      @submit-form="submitForm"
      :id="formData.id"
    />
    <!-- 上传表格弹窗 end -->
  </section>
</template>
<script>
import associationConfigDialog from "./modules/addConfig/index.vue";
import TableList from "./modules/table-list/index.vue";
import { STATUS_ENUM } from "./utils/enum/index";
import UploadFile from "./modules/uploadFile/index";
import cloneDeep from "lodash/cloneDeep";

import {
  postPlaceOfProductionSelect,
  postPlaceOfProductionList,
} from "@/api/general/origin.js";
import url from "url";
import { BASE } from "@/api";

export default {
  name: "Association",
  components: { associationConfigDialog, TableList, UploadFile },
  data() {
    return {
      STATUS_ENUM,
      statusList: Object.values(STATUS_ENUM),
      total: 0,
      loadingData: false,
      formData: {
        country_code: "",
        province_code: "",
        city_code: "",
        district_code: "",
        first_cid: "",
        name: "",
        page: 1,
        pageSize: 10,
        status: "", // 默认商品状态 为正常
      },
      formDataClone: {}, // 复制数据，用于初始化
      tableData: [{ id: 1 }], //列表数据
      firstCidList: [], //一级品类列表
      areasList: [],
      cityList: [],
      countriesList: [],
      districtList: [],
      provinceList: [],
    };
  },
  created() {
    this.postAjaxPlaceOfProductionSelect(); // 获取所有筛选项
    this.formDataClone = cloneDeep(this.formData);
    if (this.$route.query && this.$route.query.id) {
      this.formData.first_cid = Number(this.$route.query.id);
      this.firstCidList = [
        {
          id: Number(this.$route.query.id),
          name: this.$route.query.name,
        },
      ];
    }
    this.initData();
  },
  methods: {
    async postAjaxPlaceOfProductionSelect() {
      try {
        const res = await postPlaceOfProductionSelect();
        this.firstCidList = res.data.firstCidList || [];
        this.areasList = res.data.areasList || [];
        this.cityList = res.data.cityList || [];
        this.countriesList = res.data.countriesList || [];
        this.districtList = res.data.districtList || [];
        this.provinceList = res.data.provinceList || [];
      } catch (error) {
        console.log(error, "postPlaceOfProductionSelect");
      }
    },
    /**
     * 导出
     */
    async onHandleDownLoad() {
      const query = {
        ...this.formData,
        token: sessionStorage.getItem("token"),
      };
      delete query.page;
      delete query.pageSize;
      const params = url.format({ query });
      const path = BASE.PRO1 + "/place/of/production/export";
      window.open(path + params);
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.postAjaxPlaceOfProductionList();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.postAjaxPlaceOfProductionList();
    },
    /**
     * 查询
     */
    onHandleSearch() {
      this.formData.page = 1;
      this.postAjaxPlaceOfProductionList();
    },
    /**
     * 初始化数据
     */
    initData() {
      this.postAjaxPlaceOfProductionList();
    },
    /**
     * 重置数据
     */
    onHandleRefresh() {
      this.formData = {
        ...this.formDataClone,
      };
      this.postAjaxPlaceOfProductionList();
    },
    /**
     * 添加商品
     */
    onHandleZone(func, val) {
      this.$refs.associationConfig[func](val);
    },
    /**
     * 上传表格
     */
    onHandleUpload(func, val) {
      this.$refs.uploadFileRef[func](val);
    },
    /**
     * 刷新列表
     */
    submitForm() {
      this.formData.page = 1;
      this.postAjaxPlaceOfProductionList();
    },
    /**
     * 获得列表数据
     */
    async postAjaxPlaceOfProductionList() {
      this.loadingData = true;
      try {
        const { data } = await postPlaceOfProductionList(this.formData);
        this.tableData = data.data;
        this.total = data.total;
      } catch (err) {
        console.error("ajax postVersatileRegionProductList err", err);
      } finally {
        this.loadingData = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .form {
    padding: 13px 15px;
    align-items: start;
  }
  .content-wrap {
    flex: 1;
    overflow-y: hidden;
  }
  .pagination {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .headSearch {
    flex: 1;
    justify-content: end;
    gap: 15px;
    flex-wrap: wrap;
  }
}
</style>
