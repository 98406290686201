import axios from "../http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-产地-筛选
 * @param {*} params
 * @returns
 */
export function postPlaceOfProductionSelect(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/place/of/production/select`,
    data: params,
  });
}

/**
 * 总控-产地-添加/编辑产地
 * @param {*} params
 * @returns
 */
export function postPlaceOfProductionAddEdit(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/place/of/production/add_or_edit`,
    data: params,
  });
}

/**
 * 总控-产地-详情
 * @param {*} params
 * @returns
 */
export function postPlaceOfProductionInfo(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/place/of/production/info`,
    data: params,
  });
}

/**
 * 总控-产地-列表
 * @param {*} params
 * @returns
 */
export function postPlaceOfProductionList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/place/of/production/list`,
    data: params,
  });
}

/**
 * 总控-产地-删除
 * @param {*} params
 * @returns
 */
export function postPlaceOfProductionDelete(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/place/of/production/delete`,
    data: params,
  });
}

/**
 * 总控-文件上传中心
 * @param {*} params
 * @returns
 */
export function postFileUploadCenterList(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/file/upload/center/list`,
    data: params,
  });
}
