<template>
  <div>
    <!--  添加产地弹出框  -->
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents1">
        <el-form
          :model="platForm"
          :rules="rules"
          ref="platForm"
          label-width="auto"
        >
          <el-form-item label="地区" prop="area_type">
            <el-radio-group v-model="platForm.area_type" @change="radioChange">
              <el-radio label="1">国产</el-radio>
              <el-radio label="2">进口</el-radio>
            </el-radio-group>
          </el-form-item>
          <div class="specInput">
            <el-form-item
              label="选择产地"
              prop="value"
              v-if="platForm.area_type == ORIGIN_TYPE_ENUM['1'].value"
            >
              <el-cascader
                filterable
                v-model="platForm.value"
                :options="options"
                :props="props"
                clearable
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
          </div>
          <el-form-item
            label="选择国家"
            prop="country_code"
            v-if="platForm.area_type == ORIGIN_TYPE_ENUM['2'].value"
          >
            <el-select
              filterable
              v-model="platForm.country_code"
              clearable
              placeholder="请选择"
              @change="changeCountry"
            >
              <el-option
                :label="gItem.name"
                :value="gItem.code"
                v-for="(gItem, gIndex) in countriesList"
                :key="gIndex"
              ></el-option>
            </el-select>
          </el-form-item>
          <div class="specInput">
            <el-form-item label="对外显示产地" prop="name">
              <el-input
                v-model="platForm.name"
                clearable
                placeholder="请输入产地"
              ></el-input>
            </el-form-item>
          </div>
          <el-form-item label="选择品类" prop="first_cid">
            <el-select
              v-model="platForm.first_cid"
              clearable
              multiple
              placeholder="请选择"
            >
              <el-option
                :label="gItem.name"
                :value="gItem.id"
                v-for="(gItem, gIndex) in firstCidList"
                :key="gIndex"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态" prop="status">
            <el-radio-group v-model="platForm.status">
              <el-radio label="1">正常</el-radio>
              <el-radio label="2">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom" :loading="submitLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import {
  postPlaceOfProductionAddEdit,
  postPlaceOfProductionInfo,
} from "@/api/general/origin.js";
import { ORIGIN_TYPE_ENUM } from "../../utils/enum/index";

export default {
  props: ["firstCidList", "countriesList", "areasList"],
  name: "addEditConfig",
  data() {
    return {
      ORIGIN_TYPE_ENUM,
      options: [], // 产地级联
      submitLoading: false,
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "", //弹框title
      platFormClone: {}, // 复制数据，用于初始化
      platForm: {
        area_type: "1", // 默认国产
        name: "", // 对外产地名称
        first_cid: "", // 对应一级品类ID
        status: "1",
        country_code: "",
        country_name: "",
        province_code: "",
        province_name: "",
        city_code: "",
        city_name: "",
        district_code: "",
        district_name: "",
        value: [],
        id: "",
      },
      props: {
        value: "areaCode", //	指定选项的值为选项对象的某个属性值	string	—	'value'
        label: "name", //	指定选项标签为选项对象的某个属性值	string	—	'label'
        children: "children", //	指定选项的子选项为选项对象的某个属性值
        // disabled: "cid_is_audit",
        checkStrictly: true,
      },
      rules: {
        // value: [
        //   {
        //     validator: this.validateOrigin,
        //     trigger: ["blur", "change"],
        //     required: true,
        //   },
        // ],
        country_code: [
          {
            validator: this.validateCountry,
            trigger: ["blur", "change"],
            required: true,
          },
        ],
        area_type: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择地区",
          },
        ],
        name: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请输入对外产地名称",
          },
        ],
        first_cid: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择一级品类",
          },
        ],
        status: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: "请选择状态",
          },
        ],
      },
    };
  },
  created() {
    this.platFormClone = cloneDeep(this.platForm);
  },
  watch: {
    areasList(val) {
      console.log(val, "aaa");
      const processedOptions = val.map((option) => ({
        ...option,
        // value: option.areaCode,
        // label: option.name,
        children: option.citys
          ? option.citys.map((child) => ({
              ...child,
              // value: child.areaCode,
              // label: child.name,
              children: child.districts
                ? child.districts.map((grandChild) => ({
                    ...grandChild,
                    // value: grandChild.areaCode,
                    // label: grandChild.name,
                  }))
                : null,
            }))
          : null,
      }));
      this.options = processedOptions;
      console.log(processedOptions, "processedOptions==");
    },
  },
  methods: {
    // 验证国家 是否必填
    validateCountry(rule, value, callback) {
      if (this.platForm.area_type == 2 && !value) {
        callback(new Error("请选择国家"));
      } else {
        callback();
      }
    },
    // 验证产地 是否必填
    validateOrigin(rule, value, callback) {
      if (!value.length && this.platForm.area_type == 1) {
        callback(new Error("请选择产地"));
      } else {
        callback();
      }
    },
    // 切换国家
    changeCountry(e) {
      this.countriesList.map((cItem) => {
        if (cItem.code == this.platForm.country_code) {
          this.platForm.country_name = cItem.name;
        }
      });
    },
    // 选择产地 级联
    handleChange(e) {
      // 获取选中的name
      if (e.length > 0) {
        console.log("22", this.options, e[0]);
        for (let oItem of this.options) {
          if (oItem.areaCode == e[0] && e[0]) {
            console.log(oItem, "999");
            this.platForm.province_name = oItem.name;
            if (e[1] && oItem.children.length > 0) {
              for (let twoItem of oItem.children) {
                if (twoItem.areaCode == e[1]) {
                  this.platForm.city_name = twoItem.name;
                  if (e[2] && twoItem.children.length > 0) {
                    for (let threeItem of twoItem.children) {
                      if (threeItem.areaCode == e[2]) {
                        this.platForm.district_name = threeItem.name;
                      }
                    }
                  } else {
                    this.platForm.district_name = "";
                  }
                }
              }
            } else {
              this.platForm.city_name = "";
              this.platForm.district_name = "";
            }
          }
        }
        this.platForm.province_code = e[0] ? e[0] : "";
        this.platForm.city_code = e[1] ? e[1] : "";
        this.platForm.district_code = e[2] ? e[2] : "";
      } else {
        this.platForm.province_name = "";
        this.platForm.province_code = "";
        this.platForm.city_code = "";
        this.platForm.city_name = "";
        this.platForm.district_code = "";
        this.platForm.district_name = "";
      }
      console.log(this.platForm, e, "eee");
    },
    // 切换地区类型
    radioChange(e) {
      this.$refs.platForm?.clearValidate();
      // 重置清空数据
      this.platForm.value = [];
      this.platForm.country_name = "";
      this.platForm.country_code = "";
      this.platForm.province_name = "";
      this.platForm.province_code = "";
      this.platForm.city_code = "";
      this.platForm.city_name = "";
      this.platForm.district_code = "";
      this.platForm.district_name = "";
    },
    /**
     *初始化数据
     */
    initData() {
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs.platForm.clearValidate();
      });
    },
    /**
     *新增/编辑
     */
    addData(val) {
      console.log(val, "val==");
      if (val) {
        this.platFormTitle = "编辑产地";
        this.postAjaxPlaceOfProductionInfo(val.id);
      } else {
        this.platFormTitle = "添加产地";
      }
      this.platForm = {
        ...this.platFormClone,
      };
      this.initData();
    },
    async postAjaxPlaceOfProductionInfo(id) {
      try {
        const res = await postPlaceOfProductionInfo({
          id,
        });
        const tmpData = this.tool.transformKeys(res.data);
        for (let key in this.platForm) {
          console.log(tmpData[key], this.platForm[key], "ee");
          if (key == "value") {
            if (tmpData.district_code) {
              this.platForm.value = [
                tmpData.province_code,
                tmpData.city_code,
                tmpData.district_code,
              ];
            } else if (tmpData.city_code) {
              this.platForm.value = [tmpData.province_code, tmpData.city_code];
            } else {
              this.platForm.value = [tmpData.province_code];
            }
          } else if (key == "area_type") {
            this.platForm.area_type = tmpData.area_type.toString();
          } else if (key == "status") {
            this.platForm.status = tmpData.status.toString();
          } else if (key == "first_cid") {
            this.platForm.first_cid = tmpData.first_cid
              .split(",")
              .map((mItem) => (mItem = Number(mItem)));
          } else {
            this.platForm[key] = tmpData[key];
          }
        }
        this.platForm.id = tmpData.id;
        // this.platForm.area_type = res.data.area_type.toString();
        console.log(
          res,
          this.platForm,
          this.tool.transformKeys(res.data),
          "res==="
        );
      } catch (error) {
        console.log(error, "postPlaceOfProductionInfo");
      }
    },
    /**
     * 提交表单
     */
    submitFrom: debounce(function () {
      this.$refs.platForm.validate(async (valid) => {
        if (!valid) {
          return;
        }
        let params = JSON.parse(JSON.stringify(this.platForm));
        params.first_cid = this.platForm.first_cid.join(",");
        delete params.value;
        this.submitLoading = true;
        try {
          await postPlaceOfProductionAddEdit(params);
          if (params.id) {
            this.tool.message("编辑成功", "success");
          } else {
            this.tool.message("添加成功", "success");
          }
          this.$emit("submit-form"); // 刷新列表
        } catch (error) {
          console.error("postVersatileRegionProductAdd", error);
        } finally {
          this.dialogFormVisible = false;
          this.submitLoading = false;
        }
      });
    }, 300),
  },
};
</script>
<style lang="scss">
.el-radio__original {
  display: none !important; /* 隐藏原生 radio 输入，但仍然允许交互 */
}
.el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}
</style>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;

  .hide {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
  .tipWrap {
    color: red;
    margin-left: 10px;
  }
}
/deep/ .el-select {
  width: 300px !important;
}
.specInput {
  /deep/ .el-input__inner {
    width: 300px !important;
  }
}
// .el-cascader {
//   /deep/ .el-input__inner {
//     width: 300px !important;
//   }
// }
</style>
