<template>
  <el-table
    :data="tableData"
    height="100%"
    :border="true"
    :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
  >
    <el-table-column
      type="index"
      width="80"
      align="center"
      label="序号"
    ></el-table-column>
    <el-table-column
      prop="name"
      align="center"
      min-width="120"
      label="产地对外显示名称"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="kv" align="center" label="地区">
      <template slot-scope="scope">
        <div v-if="scope.row.areaType == ORIGIN_TYPE_ENUM['1'].value">
          {{ scope.row.provinceName
          }}{{ `${scope.row.cityName}` ? `-${scope.row.cityName}` : ""
          }}{{
            `${scope.row.districtName}` ? `-${scope.row.districtName}` : ""
          }}
        </div>
        <div v-else>
          {{ scope.row.countryName }}
        </div>
      </template>
    </el-table-column>
    <el-table-column prop="categoryNames" align="center" label="关联品类">
    </el-table-column>
    <el-table-column prop="stateIs2Count" align="center" label="关联在售商品数">
    </el-table-column>
    <el-table-column
      prop="stateNot2Count"
      width="300"
      align="center"
      label="关联下架/审核中商品数"
      show-overflow-tooltip
    >
    </el-table-column>
    <el-table-column prop="userName" align="center" label="创建人">
    </el-table-column>
    <el-table-column prop="createTime" align="center" label="创建时间">
    </el-table-column>
    <el-table-column prop="status" align="center" label="状态">
      <template slot-scope="scope">
        {{
          Object.values(STATUS_ENUM).find(
            (item) => scope.row.status === item.value
          )?.label || "-"
        }}
      </template>
    </el-table-column>
    <el-table-column
      prop="purchase_deduction_amount"
      align="center"
      label="操作"
    >
      <template slot-scope="scope">
        <el-button type="text" @click="onHandleEdit(scope.row)">
          编辑
        </el-button>
        <el-button type="text" @click="onHandleDelete(scope.row)">
          删除
        </el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { postPlaceOfProductionDelete } from "@/api/general/origin.js";
import { STATUS_ENUM, ORIGIN_TYPE_ENUM } from "../../utils/enum/index";
export default {
  name: "TableList",
  props: ["tableData", "id"],
  data() {
    return {
      STATUS_ENUM,
      ORIGIN_TYPE_ENUM,
    };
  },
  methods: {
    /**
     * 编辑
     */
    onHandleEdit(row) {
      this.$emit("onHandleZone", "addData", row);
    },
    /**
     * 删除
     */
    onHandleDelete(row) {
      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(async () => {
          try {
            const res = await postPlaceOfProductionDelete({
              id: row.id,
            });
            this.$message({
              type: "success",
              message: "已删除",
            });
            this.$emit("submit-form");
          } catch (error) {}
        })
        .catch(() => {
          this.$message({
            type: "error",
            message: "取消删除",
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped></style>
