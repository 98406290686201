var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "page-wrap" },
    [
      _c("section", { staticClass: "form jn-form-style" }, [
        _c(
          "div",
          { staticClass: "form-item btn-wrap" },
          [
            _c("el-button", {
              staticClass: "refresh",
              staticStyle: { "margin-left": "5px" },
              attrs: { type: "primary", icon: "el-icon-refresh" },
              on: { click: _vm.onHandleRefresh },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.onHandleZone("addData")
                  },
                },
              },
              [_vm._v(" 新 增 ")]
            ),
            _c(
              "el-button",
              {
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.onHandleUpload("addData")
                  },
                },
              },
              [_vm._v(" 导入 ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "d-flex headSearch" }, [
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "产地" },
                model: {
                  value: _vm.formData.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "name", $$v)
                  },
                  expression: "formData.name",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "国家", filterable: "" },
                  model: {
                    value: _vm.formData.country_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "country_code", $$v)
                    },
                    expression: "formData.country_code",
                  },
                },
                _vm._l(_vm.countriesList, function (countItem, countIndex) {
                  return _c("el-option", {
                    key: countIndex,
                    attrs: { label: countItem.name, value: countItem.code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "省", filterable: "" },
                  model: {
                    value: _vm.formData.province_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "province_code", $$v)
                    },
                    expression: "formData.province_code",
                  },
                },
                _vm._l(_vm.provinceList, function (pItem, pIndex) {
                  return _c("el-option", {
                    key: pIndex,
                    attrs: { label: pItem.name, value: pItem.areaCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "市", filterable: "" },
                  model: {
                    value: _vm.formData.city_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "city_code", $$v)
                    },
                    expression: "formData.city_code",
                  },
                },
                _vm._l(_vm.cityList, function (cityItem, cityIndex) {
                  return _c("el-option", {
                    key: cityIndex,
                    attrs: { label: cityItem.name, value: cityItem.areaCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    clearable: "",
                    placeholder: "区/县",
                    filterable: "",
                  },
                  model: {
                    value: _vm.formData.district_code,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "district_code", $$v)
                    },
                    expression: "formData.district_code",
                  },
                },
                _vm._l(_vm.districtList, function (areaItem, areaIndex) {
                  return _c("el-option", {
                    key: areaIndex,
                    attrs: { label: areaItem.name, value: areaItem.areaCode },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "一级品类" },
                  model: {
                    value: _vm.formData.first_cid,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "first_cid", $$v)
                    },
                    expression: "formData.first_cid",
                  },
                },
                _vm._l(_vm.firstCidList, function (firstItem, firstIndex) {
                  return _c("el-option", {
                    key: firstIndex,
                    attrs: { label: firstItem.name, value: firstItem.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs" },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "状态" },
                  model: {
                    value: _vm.formData.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "status", $$v)
                    },
                    expression: "formData.status",
                  },
                },
                _vm._l(_vm.statusList, function (sItem, sIndex) {
                  return _c("el-option", {
                    key: sIndex,
                    attrs: { label: sItem.label, value: sItem.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item btn-wrap" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-search" },
                  on: { click: _vm.onHandleSearch },
                },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-item btn-wrap" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "warning", icon: "el-icon-upload2" },
                  on: { click: _vm.onHandleDownLoad },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "content-wrap" },
        [
          _c("table-list", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loadingData,
                expression: "loadingData",
              },
            ],
            attrs: { tableData: _vm.tableData, id: _vm.formData.id },
            on: {
              "submit-form": _vm.submitForm,
              onHandleZone: _vm.onHandleZone,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "fy" },
        [
          _c("el-pagination", {
            attrs: {
              type: "primary",
              background: "",
              "current-page": _vm.formData.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.formData.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
            },
            on: {
              "size-change": _vm.onHandleSizeChange,
              "current-change": _vm.onHandleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c("association-config-dialog", {
        ref: "associationConfig",
        attrs: {
          id: _vm.formData.id,
          firstCidList: _vm.firstCidList,
          countriesList: _vm.countriesList,
          areasList: _vm.areasList,
        },
        on: { "submit-form": _vm.submitForm },
      }),
      _c("upload-file", {
        ref: "uploadFileRef",
        attrs: { id: _vm.formData.id },
        on: { "submit-form": _vm.submitForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }