var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.platFormTitle,
            visible: _vm.dialogFormVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents1" },
            [
              _c(
                "el-form",
                {
                  ref: "platForm",
                  attrs: {
                    model: _vm.platForm,
                    rules: _vm.rules,
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "上传文件", prop: "url" } },
                    [
                      _c(
                        "el-upload",
                        {
                          ref: "upload",
                          staticClass: "upload-demo",
                          attrs: {
                            action: `${_vm.BASE.PRO1}/place/of/production/import`,
                            headers: { token: _vm.token },
                            accept: ".xlsx",
                            "on-success": _vm.successFile,
                            "on-error": _vm.errorFile,
                            "before-remove": _vm.beforeRemove,
                            "before-upload": _vm.beforeUpload,
                            "on-exceed": _vm.onExceed,
                            "on-change": _vm.onChange,
                            multiple: false,
                            limit: 1,
                            "auto-upload": false,
                          },
                        },
                        [
                          _c(
                            "el-button",
                            { attrs: { size: "small", type: "primary" } },
                            [_vm._v("点击上传")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.openfile },
                    },
                    [_vm._v("下载模版")]
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "my-15" }, [
                _vm._v(" 文件上传成功后,可去 "),
                _c(
                  "span",
                  {
                    staticStyle: { color: "chocolate", cursor: "pointer" },
                    on: { click: _vm.goFileCenter },
                  },
                  [_vm._v("文件上传中心")]
                ),
                _vm._v(" 查询结果 "),
              ]),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submitFrom },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }