var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-table",
    {
      attrs: {
        data: _vm.tableData,
        height: "100%",
        border: true,
        "header-cell-style": { color: "#333333", background: "#EFF6FF" },
      },
    },
    [
      _c("el-table-column", {
        attrs: { type: "index", width: "80", align: "center", label: "序号" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "name",
          align: "center",
          "min-width": "120",
          label: "产地对外显示名称",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "kv", align: "center", label: "地区" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                scope.row.areaType == _vm.ORIGIN_TYPE_ENUM["1"].value
                  ? _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(scope.row.provinceName) +
                          _vm._s(
                            `${scope.row.cityName}`
                              ? `-${scope.row.cityName}`
                              : ""
                          ) +
                          _vm._s(
                            `${scope.row.districtName}`
                              ? `-${scope.row.districtName}`
                              : ""
                          ) +
                          " "
                      ),
                    ])
                  : _c("div", [
                      _vm._v(" " + _vm._s(scope.row.countryName) + " "),
                    ]),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: { prop: "categoryNames", align: "center", label: "关联品类" },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "stateIs2Count",
          align: "center",
          label: "关联在售商品数",
        },
      }),
      _c("el-table-column", {
        attrs: {
          prop: "stateNot2Count",
          width: "300",
          align: "center",
          label: "关联下架/审核中商品数",
          "show-overflow-tooltip": "",
        },
      }),
      _c("el-table-column", {
        attrs: { prop: "userName", align: "center", label: "创建人" },
      }),
      _c("el-table-column", {
        attrs: { prop: "createTime", align: "center", label: "创建时间" },
      }),
      _c("el-table-column", {
        attrs: { prop: "status", align: "center", label: "状态" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      Object.values(_vm.STATUS_ENUM).find(
                        (item) => scope.row.status === item.value
                      )?.label || "-"
                    ) +
                    " "
                ),
              ]
            },
          },
        ]),
      }),
      _c("el-table-column", {
        attrs: {
          prop: "purchase_deduction_amount",
          align: "center",
          label: "操作",
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (scope) {
              return [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleEdit(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 编辑 ")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.onHandleDelete(scope.row)
                      },
                    },
                  },
                  [_vm._v(" 删除 ")]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }