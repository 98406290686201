var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "dialog",
          attrs: {
            title: _vm.platFormTitle,
            visible: _vm.dialogFormVisible,
            width: "60%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "contents1" },
            [
              _c(
                "el-form",
                {
                  ref: "platForm",
                  attrs: {
                    model: _vm.platForm,
                    rules: _vm.rules,
                    "label-width": "auto",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "地区", prop: "area_type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.radioChange },
                          model: {
                            value: _vm.platForm.area_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.platForm, "area_type", $$v)
                            },
                            expression: "platForm.area_type",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("国产"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("进口"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "specInput" },
                    [
                      _vm.platForm.area_type == _vm.ORIGIN_TYPE_ENUM["1"].value
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "选择产地", prop: "value" } },
                            [
                              _c("el-cascader", {
                                attrs: {
                                  filterable: "",
                                  options: _vm.options,
                                  props: _vm.props,
                                  clearable: "",
                                },
                                on: { change: _vm.handleChange },
                                model: {
                                  value: _vm.platForm.value,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.platForm, "value", $$v)
                                  },
                                  expression: "platForm.value",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.platForm.area_type == _vm.ORIGIN_TYPE_ENUM["2"].value
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "选择国家", prop: "country_code" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: "",
                                placeholder: "请选择",
                              },
                              on: { change: _vm.changeCountry },
                              model: {
                                value: _vm.platForm.country_code,
                                callback: function ($$v) {
                                  _vm.$set(_vm.platForm, "country_code", $$v)
                                },
                                expression: "platForm.country_code",
                              },
                            },
                            _vm._l(_vm.countriesList, function (gItem, gIndex) {
                              return _c("el-option", {
                                key: gIndex,
                                attrs: { label: gItem.name, value: gItem.code },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "specInput" },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "对外显示产地", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { clearable: "", placeholder: "请输入产地" },
                            model: {
                              value: _vm.platForm.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.platForm, "name", $$v)
                              },
                              expression: "platForm.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择品类", prop: "first_cid" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            clearable: "",
                            multiple: "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.platForm.first_cid,
                            callback: function ($$v) {
                              _vm.$set(_vm.platForm, "first_cid", $$v)
                            },
                            expression: "platForm.first_cid",
                          },
                        },
                        _vm._l(_vm.firstCidList, function (gItem, gIndex) {
                          return _c("el-option", {
                            key: gIndex,
                            attrs: { label: gItem.name, value: gItem.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.platForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.platForm, "status", $$v)
                            },
                            expression: "platForm.status",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("正常"),
                          ]),
                          _c("el-radio", { attrs: { label: "2" } }, [
                            _vm._v("禁用"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.submitLoading },
                  on: { click: _vm.submitFrom },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }