<template>
  <div>
    <!--  上传表格弹出框  -->
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents1">
        <el-form
          :model="platForm"
          :rules="rules"
          ref="platForm"
          label-width="auto"
        >
          <el-form-item label="上传文件" prop="url">
            <el-upload
              :action="`${BASE.PRO1}/place/of/production/import`"
              :headers="{ token: token }"
              ref="upload"
              accept=".xlsx"
              :on-success="successFile"
              :on-error="errorFile"
              :before-remove="beforeRemove"
              :before-upload="beforeUpload"
              :on-exceed="onExceed"
              :on-change="onChange"
              class="upload-demo"
              :multiple="false"
              :limit="1"
              :auto-upload="false"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <el-button size="small" type="primary" @click="openfile"
            >下载模版</el-button
          >
        </el-form>
        <div class="my-15">
          文件上传成功后,可去
          <span style="color: chocolate; cursor: pointer" @click="goFileCenter"
            >文件上传中心</span
          >
          查询结果
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom" :loading="submitLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import { postVersatileRegionBindProductImport } from "@/api/generalControl/zone";
import { postRegionBindProductFile } from "@/api/export/index.js";
import { BASE } from "@/api";
import { downLoadFlowBlob } from "@/utils/tools/base";

export default {
  props: ["id"],
  name: "uploadFile",
  data() {
    return {
      BASE,
      token: sessionStorage.getItem("token"),
      submitLoading: false,
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "", //弹框title
      platFormClone: {}, // 复制数据，用于初始化
      fileList: [],
      platForm: {
        url: "",
      },
      rules: {
        url: [{ required: true, message: "请上传文件", trigger: "change" }],
      },
    };
  },
  created() {
    this.platFormClone = cloneDeep(this.platForm);
  },
  methods: {
    /**
     * 文件上传中心
     */
    goFileCenter() {
      this.$router.push({
        path: `/file-upload-center`,
        query: {
          //name: "11",
        },
      });
    },
    /**
     * 下载模版
     */
    openfile() {
      this.download(
        "https://jiunong-purchase.oss-cn-beijing.aliyuncs.com/general/serverData/placeOfOriginTemplate.xlsx",
        "产地模版"
      );
    },
    download(url1, fileName) {
      let self = this;
      const url = url1;
      let x = new XMLHttpRequest();
      x.open("GET", url, true);
      x.responseType = "blob";
      x.onload = function () {
        let url = window.URL.createObjectURL(x.response);
        let a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
      };
      x.send();
    },
    /**
     * 超出数量处理
     */
    onExceed() {
      this.$message.warning(`抱歉，最多只能上传1份文件`);
    },
    /**
     * 上传操作
     */
    successFile(e) {
      console.log(e, "e====");
      this.submitLoading = false;
      if (e.code == 20000) {
        this.dialogFormVisible = false;
        this.tool.message(`上传成功`, "success");
        this.$emit("submit-form"); // 刷新列表
      } else {
        this.tool.message(e.msg, "error");
      }
    },
    /**
     * 上传失败
     */
    errorFile(e) {
      this.platForm.url = "";
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
    /**
     * 删除文件
     */
    beforeRemove(file, fileList) {
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    onChange(file) {
      console.log(file, this.fileList, "fileList==");
      // 可以在这里对文件进行一些验证
      this.fileList.push(file);
    },
    //上传大小不能超过60M
    beforeUpload(file) {
      console.log(file, file.size, "www");
      const isLt60M = file.size / 1024 / 1024 < 60;
      if (!isLt60M) {
        this.$message.error("上传文件大小不能超过 60MB!");
      }
      return isLt60M;
    },
    /**
     *新增
     */
    addData() {
      this.platFormTitle = "添加产地";
      this.platForm = {
        ...this.platFormClone,
      };
      this.dialogFormVisible = true;
      this.fileList = [];
      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
        this.$refs.platForm.clearValidate();
      });
    },
    /**
     * 提交表单
     */
    submitFrom: debounce(function () {
      console.log(this.fileList, this.$refs.upload, "==");
      if (this.fileList.length == 0) {
        this.tool.message("请上传文件", "error");
        return;
      } else {
        this.submitLoading = true;
        this.$refs.upload.submit(); // 触发上传
      }
    }, 300),
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;

  .hide {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
