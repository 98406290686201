/** 状态枚举 */
export const STATUS_ENUM = {
  1: {
    value: 1,
    label: "正常",
  },
  2: {
    value: 2,
    label: "禁用",
  },
};

/** 产地类型枚举 */
export const ORIGIN_TYPE_ENUM = {
  1: {
    value: 1,
    label: "国产",
  },
  2: {
    value: 2,
    label: "进口",
  },
};
